import {useState} from "react"
import {Layout, ConfigProvider, Row, Col, ColorPicker} from "antd"
import {generate} from "@ant-design/colors"
import logo from './logo.png'
import landMainImg from './land-main.png'
import webSitesImg from './web-sites.webp'
import webAppsImg from './web-apps.webp'
import databasesImg from './databases.webp'
import consultingImg from './consulting.webp'
import botsImg from './telegram-bots.webp'
import outsourceImg from './outsource.webp'
import advantagesImg from './advantages.png'
import warrantyImg from './warranty.png'
import estimatesImg from './estimates.png'
import supportImg from './support.png'


const DEFAULT_COLOR = '#357cdf'
const SHOW_COLOR_PICKER = false

function App() {

  const [colorTheme, setColorTheme] = useState(DEFAULT_COLOR)
  const colors = generate(colorTheme)

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors[6],
        },
        components: {
          Layout: {
            headerBg: colors[2],
            footerBg: colors[9],
          },
        },
      }}
    >
      <Layout>
        {SHOW_COLOR_PICKER && <ColorPicker style={{position: 'fixed', top: 0}} defaultValue={colorTheme}
                      onChange={value => setColorTheme(value.toHexString())}/>}
        <Layout.Header style={{height: 75}}>
          <Row>
            <Col style={{width: 50, height: 75, cursor: 'pointer', position: 'relative'}} onClick={() => window.location = '/'}>
              <img style={{width: 50, height: 50, position: 'absolute', top: '50%', transform: 'translateY(-50%)'}} height={50} src={logo} alt="logo"/>
            </Col>
          </Row>
        </Layout.Header>

        <Layout.Content>

          <Row style={{background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, ${colors[2]} 100%)`}} justify="space-around" align="top">
            <Col>
              <Row justify="space-around" align="middle" gutter={100}>
                <Col>
                  <p style={{color: colors[5], fontSize: 48}}>IT AND TEA</p>
                  <p style={{fontSize: 24, maxWidth: 450, color: colors[7]}}>Ваша IT поддержка, доступная на всех уровнях</p>
                  <p style={{fontSize: 18, wordWrap: "break-word", maxWidth: 450, color: colors[6]}}>
                    Мы создаём сайты, веб-приложения и телеграм-боты, а также обеспечиваем стабильную работу серверов и баз данных для успешного развития вашего бизнеса
                  </p>
                </Col>
                <Col>
                  <img height={500} src={landMainImg} alt={'main'}/>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{background: `linear-gradient(0deg, ${colors[2]} 0%, rgba(255,255,255,1) 100%)`}} justify="space-around" align="top">
            <Col style={{marginTop: 100}}>
              <Row justify="space-around" align="middle">
                <Col span={16} style={{textAlign: 'center', fontSize: 32, color: colors[8]}}>
                  IT AND TEA предоставляет профессиональные IT-услуги для малого и среднего бизнеса
                </Col>
                <Col span={16} style={{textAlign: 'center', fontSize: 14, color: colors[7]}}>
                  <p>Наши решения помогают автоматизировать процессы, улучшить
                    взаимодействие с клиентами и повысить эффективность работы. Мы ценим индивидуальный подход к каждому
                    проекту и стремимся к долгосрочному сотрудничеству, обеспечивая поддержку и развитие ваших
                    IT-решений.</p>
                </Col>
                <Col span={18}>
                  <Row justify="space-around" style={{textAlign: 'center', marginBottom: 100}} gutter={[50, 50]}>
                    {[
                      {
                        img: webSitesImg,
                        alt: 'web-sites',
                        title: 'Веб-сайты',
                        desc: 'Создаем веб-сайты с нуля под ваши требования для любой ниши',
                      },
                      {
                        img: webAppsImg,
                        alt: 'web-apps',
                        title: 'Веб-Приложения',
                        desc: 'Разрабатываем сервисы и приложения для ваших продуктов любой сложности',
                      },
                      {
                        img: databasesImg,
                        alt: 'databases',
                        title: 'Базы данных',
                        desc: 'Занимаемся настройкой и администрированием ваших баз данных',
                      },
                      {
                        img: consultingImg,
                        alt: 'consult',
                        title: 'Консалтинг',
                        desc: 'Предоставляем экспертное мнение по планированию и разработке вашего продукта',
                      },
                      {
                        img: botsImg,
                        alt: 'bots',
                        title: 'Телеграм боты',
                        desc: 'Автоматизируем работу вашего бизнеса в Телеграм',
                      },
                      {
                        img: outsourceImg,
                        alt: 'outsource',
                        title: 'Аутсорс',
                        desc: 'Найдем вам сотрудника и усилим вашу команду',
                      },
                    ].map(item => (
                      <Col xs={24} md={8} xxl={6} className="hvr-grow" style={{
                        minHeight: 150,
                        boxSizing: 'content-box',
                        border: `1px solid ${colors[1]}`,
                        boxShadow: `0px 0px 5px 2px ${colors[3]}`,
                      }}>
                        <p><img src={item.img} height={150} alt={item.alt}/></p>
                        <p style={{fontSize: 16, color: colors[7]}}>{item.title}</p>
                        <p style={{color: colors[6]}}>{item.desc}</p>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{background: colors[1], minHeight: 500}} justify="space-around" align="top">
            <Col style={{marginTop: 50, marginBottom: 100}}>
              <Row justify="space-around" align="middle" gutter={[0, 50]}>
                <Col span={16} style={{textAlign: 'center', fontSize: 32, color: colors[8]}}>
                  Почему стоит работать с нами?
                </Col>
                <Col span={18}>
                  <Row justify="space-around" style={{textAlign: 'center'}} gutter={[10, 10]}>
                    {[
                      {
                        img: advantagesImg,
                        alt: 'advantagesImg',
                        title: 'Индивидуальный подход',
                        desc: 'Работаем прозрачно и учитываем ваши пожелания и требования',
                      },
                      {
                        img: warrantyImg,
                        alt: 'warranty',
                        title: 'Гарантия качества',
                        desc: 'Каждый проект проходит многоуровневую проверку, чтобы гарантировать его безупречную работу',
                      },
                      {
                        img: estimatesImg,
                        alt: 'estimates',
                        title: 'Соблюдение сроков',
                        desc: 'Своевременная реализация проектов. Строго соблюдаем оговорённые сроки',
                      },
                      {
                        img: supportImg,
                        alt: 'support',
                        title: 'Профессиональная поддержка',
                        desc: 'Составляем подробную документацию и обучаем ваших сотрудников',
                      },
                    ].map(item => (
                      <Col md={8} xxl={4} className="hvr-float-shadow" style={{
                        minHeight: 150,
                        boxSizing: 'content-box',
                      }}>
                        <p><img src={item.img} height={150} alt={item.alt}/></p>
                        <p style={{fontSize: 16, color: colors[7]}}>{item.title}</p>
                        <p style={{color: colors[6]}}>{item.desc}</p>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={18} style={{textAlign: 'center', fontSize: 32, color: colors[8]}}>
                  Связаться с нами
                </Col>
                <Col span={18} style={{textAlign: 'center', fontSize: 22}}>
                  <a style={{color: colors[7]}} href="mailto:contact@itandtea.work">contact@itandtea.work</a>
                </Col>
              </Row>
            </Col>
          </Row>

        </Layout.Content>

        <ConfigProvider theme={{token: {colorText: '#999'}}}>
          <Layout.Footer style={{textAlign: 'center'}}>
          <Row justify="center" style={{fontSize: 11, margin: 0, padding: 0}}>
              <Col>
                <p style={{fontSize: 15, color: '#c6d1d8'}}>"IT AND TEA" DOO</p>
                <p style={{fontSize: 11, margin: 0, padding: 0, whiteSpace: "break-spaces"}}>
                  UL. LAZI BR. 1, ST. 4, BUDVA, 85310, Montenegro{'\n'}
                  PIB 03538133{'\n'}
                  <a href="mailto:contact@itandtea.work">contact@itandtea.work</a>{'\n'}
                  {'\n'}
                  Copyright © 2023 - {new Date().getFullYear()}
                </p>
              </Col>
            </Row>
          </Layout.Footer>
        </ConfigProvider>
      </Layout>
    </ConfigProvider>
  )
}

export default App;
